import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { space, fontSizes, colors, COLOR_CONSTANTS } from 'theme'
import { Flex, Grid } from './Layout'
import { H3, Text } from './Typography'
import Button from './Button'
import logo from '../static/svg/logo.svg'
import logoUkraine from '../static/svg/logo_ukraine.svg'

const HomeLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primaryText};
  &:visited {
    color: initial;
  }
`

const StyledLink = styled(Link)`
  padding: ${space.s} ${space.m};
  color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
  text-decoration: none;
  font-size: ${fontSizes.s};
`

const StyledA = styled.a`
  padding: ${space.s} ${space.m};
  color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
  text-decoration: none;
  font-size: ${fontSizes.s};
`

const ButtonLink = styled(Button.Secondary)`
  display: flex;
  text-decoration: none;
`

const MENU_LINKS = [
  {
    label: 'For Agencies',
    to: '/agencies/',
  },
  {
    label: 'For Businesses',
    to: '/businesses/',
  },
  /*
  {
    label: 'How it works',
    to: '/how-it-works',
  },

  {
    label: 'Blog',
    to: '/insights',
  },
  */
  {
    label: 'About',
    to: '/about/',
  },
]

const HeaderLean = () => (
  <Grid gridTemplateColumns={['auto auto', 'repeat(3, auto)']} px="m" py="s" alignItems="center">
    <HomeLink to="/">
      <Flex alignItems="center">
        <img src={logoUkraine} width="50px" alt="Vista Social" />
        <Flex display={['none', 'flex']}>
          <H3 ml="xxs">Agency</H3>
          <H3 color="primary">Vista</H3>
        </Flex>
      </Flex>
    </HomeLink>
    <Flex display={['none', 'flex']} justifyContent="center" />
    <Flex justifyContent="flex-end" alignItems="center">
      <StyledLink to="/agencies">For Agencies</StyledLink>
      <StyledLink to="/businesses">For Businesses</StyledLink>
      <StyledLink to="/insights">Insights</StyledLink>
    </Flex>
  </Grid>
)

export default HeaderLean
