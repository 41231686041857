import React from 'react'
import App from 'App'
import SEO from 'components/SEO'
import { Widget } from '@typeform/embed-react'
import { H1 } from '../components/Typography'
import { Box, Flex } from '../components/Layout'
import HeaderLean from '../components/HeaderLean'
import Footer from '../components/Footer'

const FeatureRequest = () => (
  <App>
    <SEO
      title="Feature Request Form"
      description="Thank you for using Vista Social. We would love to hear your ideas on how we can make our products even better for you!"
      path="/feature-request/"
    />
    <Flex mt="xl" flexDirection="column" alignItems="center">
      <H1 textAlign="center">Request a new feature</H1>
    </Flex>
    <Box m="0 auto" px="m" maxWidth="1080px" minHeight="520px">
      <Widget id="Zlj4Byko" style={{ width: '100%', height: '550px' }} className="my-form" />
    </Box>
    <Box my="xl">
      <Footer />
    </Box>
  </App>
)

export default FeatureRequest
